import _ from 'lodash';
import React, { Component } from 'react';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import "video-react/dist/video-react.css";

class Video extends Component {
    isInitialized = false;
    playing = false;
    onMouseEnter = () => {
        this.player.play()
        this.player.muted = true
        this.playing = true
        document.addEventListener('mousemove', this.mouseMoved);
    }

    onMouseLeave = () => {
        const { stream } = this.props
        document.removeEventListener('mousemove', this.mouseMoved)
        this.playing = false
        this.player.pause()
        this.player.seek(0)
        this.player.load(stream)
    }

    handleStateChange = (state) => {
        const {isPreview, stream} = this.props
        if (isPreview && state.ended) {
            this.player.play()
        }
        if (!isPreview && state.ended) {
            this.player.load(stream)
        }
        if (isPreview && !this.initialized && state.readyState === 4) {
            this.initialized = true;
            setTimeout(() => {
                this.player.muted = true
                this.onMouseLeave();
            }, 100);

        }
    }

    mouseMoved = (event) => {
        const { id, stream } = this.props
        if (!id) this.onMouseLeave()
        const hoveringOverElement = event?.srcElement?.currentSrc.includes(stream);  // _.find(event.srcElement, e => e.currentSrc && e.currentSrc.toString() === stream.toString())
        if (this.playing && !hoveringOverElement) {
            this.onMouseLeave()
        }
    }

    componentDidMount = async () => {
        this.player.subscribeToStateChange(this.handleStateChange)
    }

    componentWillUnmount = () => {
        document.removeEventListener('mousemove', this.mouseMoved)
    }

    render() {
        const { id, stream, poster, isPreview=false } = this.props
        let controlBarDisabled = false
        let playButtonPosition = 'center'
        let volume = null
        if (isPreview) {
            controlBarDisabled = true
            playButtonPosition = 'left'
            volume = 0
        }
        return (
            <div id={id} {...(isPreview ? {onMouseOver: this.onMouseEnter, onMouseOut:this.onMouseLeave, onMouseLeave:this.onMouseLeave, className: 'player-wrap preview'} : {className: 'player-wrap'})} onClick={this.props.onClick}>
                <Player
                    ref={(player) => { 
                        this.player = player
                        }}
                    poster={poster}
                    src={stream}
                    volume={volume}
                    disableDefaultControls={isPreview}
                >
                    <ControlBar disableCompletely={controlBarDisabled}></ControlBar>
                    <BigPlayButton position={playButtonPosition} />
                </Player>
            </div>
        );
    }
}

export default Video;
